// src/pages/LoggedInHome.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { getAuth } from "firebase/auth";
import { collection, query, getDocs, orderBy, limit, doc, getDoc, addDoc, deleteDoc, where } from "firebase/firestore";
import useProtectedRoute from "../hooks/useProtectedRoute";
import Menu from "../components/Menu";
import H1 from '../components/H1/H1'; 
import WeeklyHighlights from '../components/WeeklyHighlights';
import Footer from "../components/Footer";
import MuralItemSkeleton from '../components/MuralItemSkeleton';
import MuralItem from "../components/MuralItem/MuralItem";
import H2 from '../components/H2/H2';
import './Entrepreneurs.css';
import 'react-loading-skeleton/dist/skeleton.css';

const ITEMS_PER_PAGE = 6;

const LoggedInHome = () => {
  const navigate = useNavigate();
  const isLoggedIn = useProtectedRoute();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);

  const fetchUserData = async (userId) => {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : {};
  };

  const checkIfFavorited = useCallback(
    async (itemId) => {
      const user = auth.currentUser;
      if (!user) return false;

      const favoritesRef = collection(db, "favorites");
      const q = query(
        favoritesRef,
        where("userId", "==", user.uid),
        where("muralItemId", "==", itemId),
      );
      const snapshot = await getDocs(q);
      return !snapshot.empty;
    },
    [auth.currentUser],
  );

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setCurrentUser(userSnap.data());
        }
      }
    };

    fetchCurrentUser();
  }, [auth.currentUser]);

  useEffect(() => {
      const fetchItems = async () => {
    setIsLoading(true);
    const q = query(
      collection(db, "mural"),
      orderBy("createdAt", "desc"),
      limit(ITEMS_PER_PAGE),
    );
    const snapshot = await getDocs(q);
  
    const fetchedItems = [];
    for (const doc of snapshot.docs) {
      const userData = await fetchUserData(doc.data().userId);
  
      // Check if userData and userData.address are defined
      const userName = userData ? (userData.institutionName || userData.firstName) : "Anônimo";
      const userAvatar = userData ? userData.avatar : undefined;
      const userCity = userData && userData.address ? userData.address.city : "n/a";
      const userState = userData && userData.address ? userData.address.state : "n/a";
  
      const isFavorited = await checkIfFavorited(doc.id);
      fetchedItems.push({
        id: doc.id,
        ...doc.data(),
        userName: userName,
        userAvatar: userAvatar,
        userCity: userCity,
        userState: userState,
        isFavorite: isFavorited,
      });
    }
    setItems(fetchedItems);
    setIsLoading(false);
  };
  
  // Rest of your component


    if (isLoggedIn) {
      fetchItems();
    }
  }, [isLoggedIn, checkIfFavorited]);

  const toggleFavorite = async (itemId) => {
    const user = auth.currentUser;
    if (!user) return;

    const favoritesRef = collection(db, "favorites");
    const q = query(
      favoritesRef,
      where("userId", "==", user.uid),
      where("muralItemId", "==", itemId),
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      await deleteDoc(snapshot.docs[0].ref);
    } else {
      await addDoc(favoritesRef, { userId: user.uid, muralItemId: itemId });
    }

    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, isFavorite: !snapshot.empty };
      }
      return item;
    });

    setItems(updatedItems);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <H1 
        text={`Bem vindo(a), ${currentUser ? currentUser.firstName || currentUser.institutionName : "Usuário"} :)`} 
        bgColor="purple" 
        fontColor="white" 
        />
        <H2 text="Itens sugeridos pra você" textColor="purple" />
        <div className="ads-wall-grid">
          {isLoading ? (
            Array.from({ length: 3 }, (_, index) => (
              <MuralItemSkeleton key={index} />
            ))
          ) : (
            items.slice(0, 3).map((item) => (
              <MuralItem key={item.id} item={item} onToggleFavorite={toggleFavorite} onNavigate={navigate} />
            ))
          )}
        </div>
        <hr className="horizontal-rule" />
        <H2 text="Últimas da Rede" textColor="purple" />
        <div className="ads-wall-grid">
           {isLoading ? (
            Array.from({ length: 6 }, (_, index) => (
              <MuralItemSkeleton key={index} />
            ))
          ) : (
            items.slice(3, 9).map((item) => (
              <MuralItem key={item.id} item={item} onToggleFavorite={toggleFavorite} onNavigate={navigate} />
            ))
          )}
        </div>
      </div>
      <WeeklyHighlights showJoinUsButton={true} />
      <Footer />
    </>
  );
};

export default LoggedInHome;
