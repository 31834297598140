// src/withGoogleTranslate.js
import React, { useEffect } from 'react';

const withGoogleTranslate = (WrappedComponent) => {
  const WithGoogleTranslate = (props) => {
    useEffect(() => {
      const addGoogleTranslateScript = () => {
        if (document.getElementById('google_translate_script')) return;

        const script = document.createElement('script');
        script.id = 'google_translate_script';
        script.type = 'text/javascript';
        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.onerror = () => console.error('Failed to load Google Translate script');
        document.body.appendChild(script);
      };

      window.googleTranslateElementInit = () => {
        try {
          if (window.google && window.google.translate) {
            new window.google.translate.TranslateElement(
              { pageLanguage: 'pt-BR', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
              'google_translate_element'
            );
          } else {
            console.error('Google Translate API is not available.');
          }
        } catch (e) {
          console.error('Error initializing Google Translate:', e);
        }
      };

      addGoogleTranslateScript();
    }, []);

    return (
      <>
        <div id="google_translate_element" className="fixed top-0 left-0 w-full z-50 bg-white text-center shadow-md py-2"></div>
        <WrappedComponent {...props} />
      </>
    );
  };

  return WithGoogleTranslate;
};

export default withGoogleTranslate;