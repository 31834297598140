import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useProtectedRoute from '../hooks/useProtectedRoute';
import { getAuth, onAuthStateChanged, deleteUser } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Menu from '../components/Menu';
import PersonalDataForm from '../components/PersonalDataForm';
import AddressForm from '../components/AddressForm';
import BusinessDataForm from '../components/BusinessDataForm';
import DeleteAccountPopup from '../components/DeleteAccountPopup';
import MessagePopup from '../components/MessagePopup/MessagePopup';
import Footer from '../components/Footer';

const MyAccount = () => {
    const isLoggedIn = useProtectedRoute();
    const [userId, setUserId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [accountMessage, setAccountMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({ title: '', message: '' });


    const location = useLocation();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
            }
        });

        if (location.state && location.state.message) {
            setAccountMessage(location.state.message);
        }

        return () => unsubscribe();
    }, [location, auth]);

    const handleDeleteAccount = async () => {
        try {
            await deleteDoc(doc(db, 'users', userId));
            await deleteUser(auth.currentUser);
            // Redirect to login or home page after deletion
        } catch (error) {
            console.error('Error deleting account:', error);
            setMessageContent({ title: 'Minha conta', message: 'Erro ao excluir sua conta. Tente novamente.' });
            showMessage(true);
        }
    };

    const handleDeleteAccountClick = (event) => {
        event.preventDefault();
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    if (!isLoggedIn) {
        return null;
    }

    return (
       <>
            <Menu isLoggedIn={isLoggedIn} />
            <div className="content-area-minha-conta bg-minhaconta-refugia">
                {accountMessage && (
                    <p className="text-red-600 text-center font-semibold ml-11 mt-10 mb-0">
                        {accountMessage}
                    </p>
                )}
                {userId && (
                    <>
                      <PersonalDataForm
                          userId={userId}
                          setShowPopup={setShowPopup}
                      />
                      <div className="max-w-screen-xl mx-auto px-5 mt-5 -mb-12 md:px-20">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
                          <div className="col-span-1 md:col-span-3">
                            {/* Empty div for layout consistency */}
                          </div>
                          <div className="col-span-1 md:col-span-9">
                            <div className="border-t bg-purple-refugia py-5">
                              {/* Content and sections */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                )}
                {userId && (
                    <>
                      <AddressForm
                          userId={userId}
                          setShowPopup={setShowPopup}
                      />
                      <div className="max-w-screen-xl mx-auto px-5 mt-5 -mb-12 md:px-20">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
                          <div className="col-span-1 md:col-span-3">
                            {/* Empty div for layout consistency */}
                          </div>
                          <div className="col-span-1 md:col-span-9">
                            <div className="border-t bg-purple-refugia py-5">
                              {/* Content and sections */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                )}
                {userId && (
                    <BusinessDataForm
                        userId={userId}
                        setShowPopup={setShowPopup}
                    />
                )}
                <div className="max-w-screen-xl mx-auto px-5 mt-5 mb-6 md:px-20">
                  <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
                    <div className="col-span-1 md:col-span-3">
                      {/* Empty div for layout consistency */}
                    </div>
                    <div className="col-span-1 -mt-20 md:mt-0 md:col-span-9">
                      <div className="flex justify-start">
                        <button onClick={handleDeleteAccountClick} className="delete-account-button text-red-600 text-left">Excluir minha conta</button>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
            {showMessage && (
              <MessagePopup
                title={messageContent.title}
                message={messageContent.message}
                onClose={() => setShowMessage(false)}
              />
            )}
            {showPopup && <DeleteAccountPopup setShowPopup={setShowPopup} onClose={handleClosePopup} onConfirm={handleDeleteAccount} />}
            <Footer />
        </>
    );
};

export default MyAccount;
