// src/components/H1/H1.js
import React from 'react';

const H1 = ({ text, fontColor = 'white', bgColor = 'purple' }) => {
  // Check if text contains <br /> to adjust padding
  const hasLineBreak = text.includes('<br />');
  const paddingClass = hasLineBreak ? 'py-6' : 'py-4';
  const textClass = `text-${fontColor}`;
  const commonClasses = `text-2xl md:text-5xl font-bold rounded-lg ${paddingClass} ${textClass} px-5 mt-8 mb-12 tag bg-${bgColor}`;
  return (
    <h1 className={`${commonClasses} ${textClass}`} dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default H1
