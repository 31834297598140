// src/components/CategoryFilter.js
import React from 'react';
import './CategoryFilter.css'; // You need to create this CSS file
import iconMoradia from '../assets/ico-azul-moradia.svg';
import iconAlimentacao from '../assets/ico-azul-alimentacao.svg';
import iconTrocas from '../assets/ico-azul-trocas.svg';
import iconTransporte from '../assets/ico-azul-transporte.svg';
import iconDocumentacao from '../assets/ico-azul-documentacao.svg';
import iconEmprego from '../assets/ico-azul-emprego.svg';
import iconComunicacao from '../assets/ico-azul-comunicacao.svg';
import iconTreinamento from '../assets/ico-azul-treinamento.svg';
import iconSaude from '../assets/ico-azul-saude.svg';
import iconOutros from '../assets/ico-azul-outros.svg';

const categories = {
  emprego: iconEmprego,
  moradia: iconMoradia,
  alimentacao: iconAlimentacao,
  trocas: iconTrocas,
  transporte: iconTransporte,
  documentacao: iconDocumentacao,
  comunicacao: iconComunicacao,
  treinamento: iconTreinamento,
  saude: iconSaude,
  outros: iconOutros
};

// Mapa de categorias com acentuação
const categoryNamesWithAccent = {
  moradia: 'Moradia',
  alimentacao: 'Alimentação',
  trocas: 'Trocas',
  transporte: 'Transporte',
  documentacao: 'Documentação',
  emprego: 'Emprego',
  comunicacao: 'Comunicação',
  treinamento: 'Treinamento',
  saude: 'Saúde',
  outros: 'Outros',
};


const CategoryFilter = ({ onCategorySelect }) => {
  return (
    <>
      {/* Desktop View */}
      <div className="hidden md:flex justify-center flex-wrap gap-[2.42rem] my-12 px-5">
        {Object.entries(categories).map(([category, icon]) => (
          <div 
            key={category} 
            onClick={() => onCategorySelect(category)}
            className="flex flex-col items-center cursor-pointer text-center"
          >
            <img 
              src={icon} 
              alt={category} 
              className="w-18 h-18 rounded-full mb-2"
            />
            <span className="font-semibold text-blue-800 capitalize">{categoryNamesWithAccent[category]}</span>
          </div>
        ))}
      </div>

      {/* Mobile View */}
<div className="md:hidden flex overflow-x-scroll py-4">
  <div className="flex flex-nowrap gap-8 px-5">
    {Object.entries(categories).map(([category, icon]) => (
      <div 
        key={category} 
        onClick={() => onCategorySelect(category)}
        className="flex flex-col items-center cursor-pointer text-center"
      >
        <img 
          src={icon} 
          alt={category} 
          className="w-16 h-16 rounded-full mb-2"
          style={{ minWidth: '64px', minHeight: '64px' }}
        />
        <span className="font-semibold text-blue-800 capitalize">{categoryNamesWithAccent[category]}</span>
      </div>
    ))}
  </div>
</div>
    </>
  );
};

export default CategoryFilter;