// src/components/BusinessDataForm.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import Button from './Button/Button';
import H2 from './H2/H2';
import MessagePopup from './MessagePopup/MessagePopup';

const BusinessDataForm = ({ userId, setShowPopup }) => {
  const [businessData, setBusinessData] = useState({
    companyName: '',
    category: '',
    instagram: '',
    facebook: '',
    website: '',
    businessEmail: '',
    businessPhone: '',
    businessCity: '',
    businessState: '',
    businessNeighborhood: '',
    businessAddress: '',
    businessAdditionalInfo: '',
    businessZipCode: '',
    businessHours: '',
    aboutBusiness: '',
    profileVisibility: '1'
  });
  const [existingDocId, setExistingDocId] = useState(null);
  const [showBusinessForm, setShowBusinessForm] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({ title: '', message: '' });

  const brazilianStates = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO",
    "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI",
    "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  const categories = {
    moradia: 'Moradia',
    alimentacao: 'Alimentação',
    trocas: 'Trocas',
    transporte: 'Transporte',
    documentacao: 'Documentação',
    emprego: 'Emprego',
    comunicacao: 'Comunicação',
    treinamento: 'Treinamento',
    saude: 'Saúde',
    outros: 'Outros',
  };

  const handleCepChange = async (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, ""); // Remove non-digits
    value = value.replace(/^(\d{5})(\d)/, "$1-$2"); // Add hyphen after 5 digits
    
    setBusinessData({ ...businessData, businessZipCode: value });

    const cleanCep = value.replace('-', '');
    if (/^\d{5}-\d{3}$/.test(value)) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cleanCep}/json/`);
        if (!response.data.erro) {
          setBusinessData(prevState => ({
            ...prevState,
            businessCity: response.data.localidade,
            businessState: response.data.uf,
            businessNeighborhood: response.data.bairro,
            businessAddress: response.data.logradouro,
          }));
        }
      } catch (error) {
        console.error('Error fetching CEP:', error);
      }
    }
  };

  useEffect(() => {
    const fetchBusinessData = async () => {
      if (userId) {
        const q = query(collection(db, "entrepreneurs"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0];
          setBusinessData(docData.data());
          setExistingDocId(docData.id);
        }
      }
    };

    fetchBusinessData();
  }, [userId]);

  const handleShowBusinessForm = () => {
    setShowBusinessForm(true);
  };

  const handleInputChange = (e) => {
    setBusinessData({ ...businessData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const businessDataWithUserId = { ...businessData, userId };
      // Additional validation for CEP
      if (businessData.businessZipCode.length !== 9) {
        setMessageContent({ title: 'Minha conta', message: 'Por favor, insira um CEP válido.' });
        setShowMessage(true);
        return;
      }

      if (existingDocId) {
        // Update the existing document
        const docRef = doc(db, 'entrepreneurs', existingDocId);
        await updateDoc(docRef, businessDataWithUserId);
        setMessageContent({ title: 'Minha conta', message: 'Dados da empresa atualizados com sucesso!' });
        setShowMessage(true);
      } else {
        // Create a new document
        const entrepreneursRef = collection(db, 'entrepreneurs');
        await addDoc(entrepreneursRef, businessDataWithUserId);
        setMessageContent({ title: 'Minha conta', message: 'Dados da empresa adicionados com sucesso!' });
        setShowMessage(true);
      }
    } catch (error) {
      console.error('Erro ao adicionar ou atualizar os dados da empresa:', error);
      setMessageContent({ title: 'Minha conta', message: 'Erro ao adicionar ou atualizar os dados da empresa.' });
      setShowMessage(true);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto px-5 mt-12 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
        <div className="col-span-1 md:col-span-3">
          {/* Empty div for layout consistency */}
        </div>
        <div className="col-span-1 md:col-span-9">
          <H2 text="Você é um empreendedor?" textColor="purple" />
          <div className="grid grid-cols-1 md:grid-cols-9 gap-4 mb-12">
            <div className="col-span-1 md:col-span-6">
              <p className="paragraph-empreendedor">Se você for um empreendedor e quiser cadastrar o seu negócio para ser publicado na vitrine de empreendedores basta preencher um cadastro sobre o seu negócio.</p>
            </div>
            <div className="col-span-1 md:col-span-3">
              <div className="flex justify-end">
                <Button text="Cadastrar meu negócio" variant="transparent" onClick={handleShowBusinessForm} />
              </div>
            </div>
          </div>
          {showBusinessForm && (
            <>
              <H2 text="Dados da sua empresa" textColor="purple" />
              <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
                {/* Profile Visibility Section */}
                <div className="form-group mb-4 col-span-1 md:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Exibir dados da minha empresa no meu perfil?
                    <div className="mt-2">
                      <label className="inline-flex items-center">
                        <input type="radio" name="profileVisibility" value="1" checked={businessData.profileVisibility === '1'} onChange={handleInputChange} className="form-radio h-4 w-4 text-indigo-600" />
                        <span className="ml-2">Sim</span>
                      </label>
                      <label className="inline-flex items-center ml-6">
                        <input type="radio" name="profileVisibility" value="0" checked={businessData.profileVisibility === '0'} onChange={handleInputChange} className="form-radio h-4 w-4 text-indigo-600" />
                        <span className="ml-2">Não</span>
                      </label>
                    </div>
                  </label>
                </div>
            
                {/* Company Name */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Nome da Empresa
                    <input type="text" name="companyName" value={businessData.companyName} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Category Selector */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Categoria
                    <select name="category" value={businessData.category} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1">
                      <option value="">Selecione uma categoria</option>
                      {Object.entries(categories).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                  </label>
                </div>
            
                {/* Instagram */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Instagram
                    <input type="text" name="instagram" value={businessData.instagram} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Facebook */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Facebook
                    <input type="text" name="facebook" value={businessData.facebook} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Website */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Site
                    <input type="text" name="website" value={businessData.website} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Business Email */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    E-mail
                    <input type="email" name="businessEmail" value={businessData.businessEmail} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* CEP */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    CEP
                    <input type="text" name="businessZipCode" value={businessData.businessZipCode} onChange={handleCepChange} maxLength="9" className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Phone */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Telefone
                    <input type="text" name="businessPhone" value={businessData.businessPhone} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* City */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Cidade
                    <input type="text" name="businessCity" value={businessData.businessCity} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* State Selector */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Estado
                    <select name="businessState" className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" value={businessData.businessState} onChange={handleInputChange}>
                      <option value="">Selecione um estado</option>
                      {brazilianStates.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </label>
                </div>
            
                {/* Neighborhood */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Bairro
                    <input type="text" name="businessNeighborhood" value={businessData.businessNeighborhood} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Address */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Endereço
                    <input type="text" name="businessAddress" value={businessData.businessAddress} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Additional Info */}
                <div className="form-group mb-4 col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Complemento
                    <input type="text" name="businessAdditionalInfo" value={businessData.businessAdditionalInfo} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Business Hours */}
                <div className="form-group mb-4 col-span-1 md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Horário de funcionamento
                    <input type="text" name="businessHours" value={businessData.businessHours} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* About Business */}
                <div className="form-group mb-4 col-span-1 md:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Sobre sua empresa
                    <textarea name="aboutBusiness" value={businessData.aboutBusiness} onChange={handleInputChange} maxLength="250" className="w-full h-36 border border-gray-300 rounded-lg px-2.5 mt-1" />
                  </label>
                </div>
            
                {/* Submit Button */}
                <div className="form-group mt-4 col-span-1 md:col-span-3">
                  <div className="flex justify-end mt-4">
                    <Button text="Atualizar meus dados" variant="transparent" type="submit" />
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {showMessage && (
        <MessagePopup
          title={messageContent.title}
          message={messageContent.message}
          onClose={() => setShowMessage(false)}
        />
      )}
    </div>
  );
};

export default BusinessDataForm;


