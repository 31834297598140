// src/hooks/geocoding.js
const OPENCAGE_API_KEY = '9d0e8f66dfc7472e81d478ea1d616613';

export const getCoordsFromZipCode = async (zipCode) => {
  const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(zipCode)}&key=${OPENCAGE_API_KEY}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry;
      return { lat, lon: lng };
    }
    return null;
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    return null;
  }
};
