// src/components/MuralItemDetailsPopup.js
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getCoordsFromZipCode } from '../hooks/geocoding';
import haversineDistance from '../hooks/useHaversineDistance'; 
import Button from './Button/Button';
import H3 from './H3/H3';
import AuthorDetails from './AuthorDetails';
import closeBtnImage from '../assets/btn-close.png';
import locationIcon from '../assets/ico-km-distancia.svg';
import iconMoradia from '../assets/ico-azul-moradia.svg';
import iconAlimentacao from '../assets/ico-azul-alimentacao.svg';
import iconTrocas from '../assets/ico-azul-trocas.svg';
import iconTransporte from '../assets/ico-azul-transporte.svg';
import iconDocumentacao from '../assets/ico-azul-documentacao.svg';
import iconEmprego from '../assets/ico-azul-emprego.svg';
import iconComunicacao from '../assets/ico-azul-comunicacao.svg';
import iconTreinamento from '../assets/ico-azul-treinamento.svg';
import iconSaude from '../assets/ico-azul-saude.svg';
import iconOutros from '../assets/ico-azul-outros.svg';
import heartIconOver from '../assets/ico-coracao-over.svg';
import heartIcon from '../assets/ico-favorito.svg';

const categoryIcons = {
  moradia: iconMoradia,
  alimentacao: iconAlimentacao,
  trocas: iconTrocas,
  transporte: iconTransporte,
  documentacao: iconDocumentacao,
  emprego: iconEmprego,
  comunicacao: iconComunicacao,
  treinamento: iconTreinamento,
  saude: iconSaude,
  outros: iconOutros
};

// Mapa de categorias com acentuação
const categoryNamesWithAccent = {
  moradia: 'Moradia',
  alimentacao: 'Alimentação',
  trocas: 'Trocas',
  transporte: 'Transporte',
  documentacao: 'Documentação',
  emprego: 'Emprego',
  comunicacao: 'Comunicação',
  treinamento: 'Treinamento',
  saude: 'Saúde',
  outros: 'Outros',
};

const MuralItemDetailsPopup = ({ item, onClose, onSendMessage, authorInfo, onToggleFavorite }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [distance, setDistance] = useState(null);
  const isDemand = item.type === 'demand';
  const isCurrentUser = currentUser && item.userId === currentUser.uid;
  const categoryIcon = categoryIcons[item.category];
  const [isHeartHovered, setIsHeartHovered] = useState(false);

  // Format the date to DD/MM/YYYY
  const formatDate = (date) => {
    if (date) {
      const d = new Date(date.seconds * 1000);
      return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    }
    return 'Data não disponível';
  };

  useEffect(() => {
    if (navigator.geolocation && item.zipCode) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        };
        const itemCoords = await getCoordsFromZipCode(item.zipCode);

        if (itemCoords) {
          const dist = haversineDistance(userLocation, itemCoords);
          setDistance(Math.round(dist) + 'km');
        }
      }, (error) => {
        console.error('Error obtaining location:', error);
      });
    }
  }, [item.zipCode]);

  return (
    <div className="fixed inset-0 z-[9998] bg-yellow-300 flex justify-center items-center">
      <div className="relative bg-white rounded-lg p-8 flex flex-col w-5/6 h-[90%] md:mt-0 md:w-[800px] md:h-[540px]">
        <button
          className="absolute z-[9999] top-[-1rem] right-[-1rem] w-8 h-8 bg-transparent border-none cursor-pointer p-0"
          onClick={onClose}
        >
          <img src={closeBtnImage} alt="Close" />
        </button>

        <div className="flex-1 overflow-y-auto mb-5">
          <div className="flex justify-between items-start">
            <div className={`px-3 py-3 text-white font-bold ${isDemand ? 'bg-pink-500' : 'bg-green-500'} rounded-tr-2xl rounded-br-2xl rounded-bl-2xl`}>
              {isDemand ? "Demanda" : "Oferta"}
            </div>
            {!isCurrentUser && (
              <button 
                className="w-6 h-6 flex items-center justify-center"
                onMouseEnter={() => setIsHeartHovered(true)}
                onMouseLeave={() => setIsHeartHovered(false)}
                onClick={() => onToggleFavorite(item.id)}
              >
                <img 
                  src={isHeartHovered ? heartIconOver : (item.isFavorite ? heartIconOver : heartIcon)}
                  alt="Favoritar"
                  className="w-6 h-6"
                />
              </button>
            )}
          </div>

          <div className="flex justify-between items-start mt-5">
            {categoryIcon && (
              <div className="flex items-center">
                <img src={categoryIcon} alt={categoryNamesWithAccent[item.category]} className="w-7 h-7 rounded-full" />
                <span className="text-blue-800 font-bold ml-2 capitalize">{categoryNamesWithAccent[item.category]}</span>
              </div>
            )}
          </div>

          <div className="flex flex-col md:flex-row w-full mt-5">
            <div className="w-full md:w-4/5 pr-2">
              <div className="flex flex-col md:flex-row justify-between items-baseline mb-2">
                <div className="w-full md:w-3/5">
                  <H3 text={item.title} textColor="purple" />
                </div>
                <div className="w-full md:w-2/5 text-sm text-gray-500 flex items-center">
                  {distance && (
                    <span className="flex items-center mr-1">
                      <img src={locationIcon} alt="Location" className="mural-location-icon mr-1" />
                      {distance}
                    </span>
                  )}
                  <span>{formatDate(item.createdAt)}</span>
                </div>
              </div>
              <p className="font-normal text-base text-gray-600 mb-4">{item.description}</p>
              {!isCurrentUser && (
                <div className="md:absolute md:bottom-10 md:left-8">
                  <Button text="Enviar mensagem" variant="transparent" onClick={onSendMessage} />
                </div>
              )}
            </div>
          
            <div className="w-full md:w-2/5 mt-5 md:mt-0">
              {authorInfo && <AuthorDetails authorInfo={authorInfo} title="Sobre o autor" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MuralItemDetailsPopup;
